import { Col, Container, Row } from "react-bootstrap";

import HomeShape from "../../components/core/ux/HomeShape";
import Layout from "../../components/core/Layout";
import { NULL_STRING } from "../../components/queries/JobsQuery";
import { PROJECT } from "../../components/core/nav/NavigationItems";
import React from "react";
import { graphql } from "gatsby";

export default function ProjectTemplate({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const image = [NULL_STRING, "/media/"].includes(frontmatter.image)
    ? ""
    : frontmatter.image;
  const rex = /<img[^>]+src="?([^"\s]+)"?\s*/gi;
  const images = [];
  let m;
  while ((m = rex.exec(html))) {
    images.push(m[1]);
  }

  return (
    <Layout page={PROJECT} src={image} alt={frontmatter.shortname}>
      <HomeShape shortname={frontmatter.shortname} />
      <Container className="project-template">
        <Row>
          <Col lg={12}>
            {frontmatter.type !== NULL_STRING && (
              <>
                <h2 className="text-uppercase h-muted">Art des Projektes</h2>
                <h3 className="text-uppercase">{frontmatter.type}</h3>
                <div className="h mb-4" />
              </>
            )}
            {frontmatter.units !== NULL_STRING && (
              <>
                <h2 className="text-uppercase h-muted">Einheiten</h2>
                <h3>{frontmatter.units}</h3>
                <div className="h mb-4" />
              </>
            )}
            {frontmatter.address !== NULL_STRING && (
              <>
                <h2 className="text-uppercase h-muted">Objektadresse</h2>
                <h3>{frontmatter.address}</h3>
                <div className="h mb-4" />
              </>
            )}
            {frontmatter.sizes !== NULL_STRING && (
              <>
                <h2 className="text-uppercase h-muted">BGF/NRF/WFL</h2>
                <h3 dangerouslySetInnerHTML={{ __html: frontmatter.sizes }} />
                <div className="h mb-4" />
              </>
            )}
            {frontmatter.finishedon !== `${NULL_STRING}` &&
              ![null, undefined].includes(frontmatter.finishedon) && (
                <>
                  <h2 className="text-uppercase h-muted">Fertigstellung</h2>
                  <h3>{frontmatter.finishedon}</h3>
                  <div className="h mb-4" />
                </>
              )}
            {/*
              <div
              className="my-5"
              dangerouslySetInnerHTML={{
                __html: html.replaceAll(
                  /<img[^>]+src="?([^"\s]+)"?\s*[^>]+>/gi,
                  ""
                ),
              }}
              */}
            <div
              className="my-5"
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          </Col>
          <Col></Col>
        </Row>
        {/*
          {images.map((src, idx) => (
          <img
            key={idx}
            src={src}
            alt={frontmatter.bildverzeichnis}
            style={{ marginBottom: "1em", maxHeight: "700px" }}
          />
        ))}
        */}
      </Container>
    </Layout>
  );
}
export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        shortname
        sortorder
        location
        address
        type
        units
        sizes
        projectstatus
        date
        image
        longname
        projectline
        finishedon
      }
    }
  }
`;
